import React, { useState, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import {
  ApplicationPayload,
  Page,
} from "@appsmith/constants/ReduxActionConstants";
import { connect, useSelector } from "react-redux";
import { AppState } from "@appsmith/reducers";
import {
  getCurrentPageId,
  getViewModePageList,
  getCurrentPageDescription,
} from "selectors/editorSelectors";
import { FormDialogComponent } from "components/editorComponents/form/FormDialogComponent";
import AppInviteUsersForm from "pages/workspace/AppInviteUsersForm";
import { getCurrentWorkspaceId } from "@appsmith/selectors/workspaceSelectors";

import { getCurrentUser } from "selectors/usersSelectors";
import { ANONYMOUS_USERNAME, User } from "constants/userConstants";
import { Theme } from "constants/DefaultTheme";
import ProfileDropdown from "pages/common/ProfileDropdown";
import PageTabsContainer from "./PageTabsContainer";
import { getThemeDetails, ThemeMode } from "selectors/themeSelectors";
import { showAppInviteUsersDialogSelector } from "selectors/applicationSelectors";
import { getSelectedAppTheme } from "selectors/appThemingSelectors";
import HtmlTitle from "./AppViewerHtmlTitle";
import PrimaryCTA from "./PrimaryCTA";
import Button from "./AppViewerButton";
import MenuIcon from "remixicon-react/MenuFillIcon";
import CloseIcon from "remixicon-react/CloseFillIcon";
import PageMenu from "./PageMenu";
import BackToHomeButton from "@appsmith/pages/AppViewer/BackToHomeButton";
import TourCompletionMessage from "pages/Editor/GuidedTour/TourCompletionMessage";
import { useHref } from "pages/Editor/utils";
import { builderURL } from "RouteBuilder";
import {
  createMessage,
  INVITE_USERS_MESSAGE,
  INVITE_USERS_PLACEHOLDER,
} from "@appsmith/constants/messages";
import { getAppsmithConfigs } from "@appsmith/configs";

const { cloudHosting } = getAppsmithConfigs();

/**
 * ----------------------------------------------------------------------------
 * STYLED
 *-----------------------------------------------------------------------------
 */

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.header.tabsHorizontalSeparator};
`;

const HeaderRightItemContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const CustomBottom = styled.div`
  position: fixed;
  bottom: 0;
  padding: 15px 30px;
  width: 100%;
  z-index: 1000;
  color: #fff;
  background-color: #211f54;
`;

const CustomHeader = styled.div`
  position: fixed;
  to: 0;
  padding: 15px 45px;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0px 3.97327px 19.8663px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  button {
    margin: 0 10px;
  }
  .rightCustomMenu {
    display: flex;
  }
  .rightBorder {
    border-right: 1px solid #c8c8c8;
  }
`;

type AppViewerHeaderProps = {
  currentApplicationDetails?: ApplicationPayload;
  pages: Page[];
  currentWorkspaceId: string;
  currentUser?: User;
  lightTheme: Theme;
};

export function AppViewerHeader(props: AppViewerHeaderProps) {
  const history = useHistory();
  const selectedTheme = useSelector(getSelectedAppTheme);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const {
    currentApplicationDetails,
    currentUser,
    currentWorkspaceId,
    pages,
  } = props;
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const isEmbed = queryParams.get("embed");
  const hideHeader = !!isEmbed;
  const showAppInviteUsersDialog = useSelector(
    showAppInviteUsersDialogSelector,
  );
  const pageId = useSelector(getCurrentPageId);
  const editorURL = useHref(builderURL, { pageId });
  const description = useSelector(getCurrentPageDescription);
  const isFutura = currentApplicationDetails?.name?.includes("futura");
  const isLogin = window?.location?.pathname?.includes("sesion");
  if ((hideHeader && !isFutura) || isLogin) return <HtmlTitle />;

  return (
    <ThemeProvider theme={props.lightTheme}>
      <>
        {isFutura ? (
          <>
            <CustomBottom>
              <span>FUTURA © 2023</span>
            </CustomBottom>
            <CustomHeader>
              <div>
                <a href="inicio-650245777b82690e01e9712d?branch=main&embed=true">
                  <img src="https://uploads-ssl.webflow.com/632b689f3a48f1d8bbd99d86/6335eebdd547037d8cd544cb_futura-logo.svg" />
                </a>
              </div>
              <div className="rightCustomMenu">
                <div className="rightBorder">
                  <Button
                    borderRadius={
                      selectedTheme.properties.borderRadius.appBorderRadius
                    }
                    boxShadow="none"
                    buttonColor={selectedTheme.properties.colors.primaryColor}
                    buttonVariant="TERTIARY"
                    className="h-8"
                    data-cy="viewmode-share"
                    onClick={() => {
                      history.push(
                        "inicio-650245777b82690e01e9712d?branch=main&embed=true",
                      );
                    }}
                    text="Inicio"
                  />
                  <Button
                    borderRadius={
                      selectedTheme.properties.borderRadius.appBorderRadius
                    }
                    boxShadow="none"
                    buttonColor={selectedTheme.properties.colors.primaryColor}
                    buttonVariant="TERTIARY"
                    className="h-8"
                    data-cy="viewmode-share"
                    onClick={() => {
                      history.push(
                        "pacientes-650245787b82690e01e9714d?branch=main&embed=true",
                      );
                    }}
                    text="Pacientes"
                  />
                  <Button
                    borderRadius={
                      selectedTheme.properties.borderRadius.appBorderRadius
                    }
                    boxShadow="none"
                    buttonColor={selectedTheme.properties.colors.primaryColor}
                    buttonVariant="TERTIARY"
                    className="h-8"
                    data-cy="viewmode-share"
                    onClick={() => {
                      history.push(
                        "ordenes-prod-650245787b82690e01e97137?branch=main&embed=true",
                      );
                    }}
                    text="Órdenes Clínicas"
                  />
                </div>
                <div>
                  <Button
                    borderRadius={
                      selectedTheme.properties.borderRadius.appBorderRadius
                    }
                    boxShadow="none"
                    buttonColor={selectedTheme.properties.colors.primaryColor}
                    buttonVariant="TERTIARY"
                    className="h-8"
                    data-cy="viewmode-share"
                    onClick={() => {
                      history.push(
                        "mi-perfil-650245787b82690e01e97131?branch=main&embed=true",
                      );
                    }}
                    text="Mi cuenta"
                  />
                </div>
              </div>
            </CustomHeader>
          </>
        ) : (
          <nav
            className="relative bg-white js-appviewer-header"
            data-testid={"t--appsmith-app-viewer-header"}
            ref={headerRef}
          >
            <HtmlTitle
              description={description}
              name={currentApplicationDetails?.name}
            />
            <HeaderRow className="relative h-12 px-3 py-3 md:px-6">
              <section className="flex items-center gap-3 z-1">
                <div
                  className="block w-5 h-5 cursor-pointer md:hidden"
                  onClick={() => setMenuOpen((prevState) => !prevState)}
                >
                  {isMenuOpen ? (
                    <CloseIcon className="w-5 h-5" />
                  ) : (
                    <MenuIcon className="w-5 h-5" />
                  )}
                </div>
                <div className="">
                  {currentUser?.username !== ANONYMOUS_USERNAME && (
                    <BackToHomeButton />
                  )}
                </div>
              </section>
              <div className="absolute top-0 bottom-0 flex items-center justify-center w-full mt-auto text-center">
                <div className="w-7/12 overflow-hidden text-base font-medium text-gray-600 overflow-ellipsis whitespace-nowrap">
                  {currentApplicationDetails?.name}
                </div>
              </div>
              <section className="relative flex items-center ml-auto space-x-3 z-1">
                {currentApplicationDetails && (
                  <div className="hidden space-x-3 md:flex">
                    <FormDialogComponent
                      Form={AppInviteUsersForm}
                      applicationId={currentApplicationDetails.id}
                      canOutsideClickClose
                      headerIcon={{
                        name: "right-arrow",
                        bgColor: "transparent",
                      }}
                      isOpen={showAppInviteUsersDialog}
                      message={createMessage(
                        INVITE_USERS_MESSAGE,
                        cloudHosting,
                      )}
                      placeholder={createMessage(
                        INVITE_USERS_PLACEHOLDER,
                        cloudHosting,
                      )}
                      title={currentApplicationDetails.name}
                      trigger={
                        <Button
                          borderRadius={
                            selectedTheme.properties.borderRadius
                              .appBorderRadius
                          }
                          boxShadow="none"
                          buttonColor={
                            selectedTheme.properties.colors.primaryColor
                          }
                          buttonVariant="SECONDARY"
                          className="h-8"
                          data-cy="viewmode-share"
                          text="Share"
                        />
                      }
                      workspaceId={currentWorkspaceId}
                    />

                    <HeaderRightItemContainer>
                      <PrimaryCTA
                        className="t--back-to-editor"
                        url={editorURL}
                      />
                    </HeaderRightItemContainer>
                  </div>
                )}
                {currentUser && currentUser.username !== ANONYMOUS_USERNAME && (
                  <HeaderRightItemContainer>
                    <ProfileDropdown
                      modifiers={{
                        offset: {
                          enabled: true,
                          offset: `0, 0`,
                        },
                      }}
                      name={currentUser.name}
                      photoId={currentUser?.photoId}
                      userName={currentUser?.username || ""}
                    />
                  </HeaderRightItemContainer>
                )}
              </section>
            </HeaderRow>
            <PageTabsContainer
              currentApplicationDetails={currentApplicationDetails}
              pages={pages}
            />
          </nav>
        )}
        <PageMenu
          application={currentApplicationDetails}
          headerRef={headerRef}
          isOpen={isMenuOpen}
          pages={pages}
          setMenuOpen={setMenuOpen}
          url={editorURL}
        />
        <TourCompletionMessage />
      </>
    </ThemeProvider>
  );
}

const mapStateToProps = (state: AppState): AppViewerHeaderProps => ({
  pages: getViewModePageList(state),
  currentApplicationDetails: state.ui.applications.currentApplication,
  currentWorkspaceId: getCurrentWorkspaceId(state),
  currentUser: getCurrentUser(state),
  lightTheme: getThemeDetails(state, ThemeMode.LIGHT),
});

export default connect(mapStateToProps)(AppViewerHeader);
